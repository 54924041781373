import * as React from "react";
import { WizardCruisePWAProps, WizardCruisePWAFlexModuleResult } from "./typings";
import { inject, observer } from "mobx-react";
import { WizardCruisePWAView } from "./WizardCruisePWAView";
import { WizardCruisePWA3PPView } from "./WizardCruisePWA3PPView";

@inject("wizardState", "flexModuleModelStore", "context")
@observer
export class WizardCruisePWA extends React.Component<WizardCruisePWAProps> {
  constructor(props: WizardCruisePWAProps) {
    super(props);

    const cruiseWizardState = this.props.wizardState.cruiseWizardState;
    const { templateComponent, flexModuleModelStore } = props;
    if (templateComponent) {
      const { metadata } = templateComponent;
      const { id } = metadata;
      const model = flexModuleModelStore.getModel(id) as WizardCruisePWAFlexModuleResult | null;
      if (model) {
        cruiseWizardState.overrideConfig(() => {
          cruiseWizardState.config.elementId = id;
          cruiseWizardState.config.heading.fixedTitle = model.fixedLobTitle;
          cruiseWizardState.config.heading.lobSubtitleBreakPoint =
            model.lobSubtitleBreakPoint || cruiseWizardState.config.heading.lobSubtitleBreakPoint;
          cruiseWizardState.config.heading.hideLobTitle = model.hideLobTitle;
        });
        cruiseWizardState.updateDateFromConfig();
      }
    }
  }

  public render() {
    if (this.props.wizardState.cruiseWizardState.config.displayWizard3pp) {
      return <WizardCruisePWA3PPView {...this.props} />;
    }

    return <WizardCruisePWAView {...this.props} />;
  }
}

export default WizardCruisePWA;
