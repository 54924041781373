import * as React from "react";
import { observer } from "mobx-react";
import { CruiseCommon3PPSelect } from "./CruiseCommon3PPSelect";
import { useLocalization } from "@shared-ui/localization-context";
import { Cruise3PPDatesProps } from "../typings";

export const Cruise3PPDates = observer((props: Cruise3PPDatesProps) => {
  const { formatText } = useLocalization();
  const { items } = props;
  const acceptedItems: { year: number; month: number; text: string; value: string }[] = [];
  const currentDate = new Date();

  items.forEach((item) => {
    const { monthKey, month, years } = item;
    for (const year in years) {
      if (new Date(+year, month) >= currentDate) {
        acceptedItems.push({
          year: +year,
          month,
          text: `${formatText(monthKey)} ${year}`,
          value: years[year],
        });
      }
    }
  });

  acceptedItems.sort((a, b) => {
    /* istanbul ignore if */
    if (a.year !== b.year) {
      return a.year - b.year;
    }

    return a.month - b.month;
  });

  const newItems = acceptedItems.map((item) => {
    return {
      text: item.text,
      value: item.value,
    };
  });

  return <CruiseCommon3PPSelect {...props} items={newItems} />;
});
