import { locKeys } from "components/flexComponents/WizardCruisePWA/l10n";

export interface IDestination {
  value: string;
  locKey: string;
}

export interface IDestinationGroup {
  locKey: string;
  items: IDestination[];
}

export interface IDestinationGroups {
  mostPopular: IDestinationGroup;
  other: IDestinationGroup;
}

export const DESTINATION_GROUPS: IDestinationGroups = {
  mostPopular: {
    locKey: locKeys.mostPopular,
    items: [
      { value: "caribbean", locKey: locKeys.caribbean },
      { value: "bahamas", locKey: locKeys.bahamas },
      { value: "mexico", locKey: locKeys.mexico },
      { value: "alaska", locKey: locKeys.alaska },
      { value: "europe", locKey: locKeys.europe },
      { value: "bermuda", locKey: locKeys.bermuda },
      { value: "hawaii", locKey: locKeys.hawaii },
      { value: "canada-new-england", locKey: locKeys.canadaNewEngland },
    ],
  },
  other: {
    locKey: locKeys.otherDestinations,
    items: [
      { value: "arctic-antarctic", locKey: locKeys.arcticAntarctic },
      { value: "middle-east", locKey: locKeys.middleEast },
      { value: "africa", locKey: locKeys.africa },
      { value: "panama-canal", locKey: locKeys.panamaCanal },
      { value: "asia", locKey: locKeys.asia },
      { value: "pacific-coastal ", locKey: locKeys.pacificCoastal },
      { value: "australia-new-zealand", locKey: locKeys.australiaNewZealand },
      { value: "central-america", locKey: locKeys.centralAmerica },
      { value: "galapagos", locKey: locKeys.galapagos },
      { value: "getaway-at-sea", locKey: locKeys.getaway },
      { value: "transatlantic", locKey: locKeys.transatlantic },
      { value: "world-cruise ", locKey: locKeys.world },
      { value: "south-america", locKey: locKeys.southAmerica },
      { value: "south-pacific ", locKey: locKeys.southPacific },
      { value: "transpacific", locKey: locKeys.transpacific },
    ],
  },
};
