import * as React from "react";

import { UitkSelect } from "@egds/react-core/input";
import { DESTINATION_GROUPS, IDestinationGroup, IDestinationGroups } from "./destinations";
import { useLocalization } from "@shared-ui/localization-context";
import { locKeys } from "components/flexComponents/WizardCruisePWA/l10n";

export interface CruiseDestinationSelectProps {
  onChange: (selectEvent: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  value: string;
  destinationInvalidKey?: string;
  inputRef?: React.RefObject<HTMLSelectElement>;
}

export const CruiseDestinationSelect: React.FC<CruiseDestinationSelectProps> = (props) => {
  const { name, onChange, value, destinationInvalidKey, inputRef } = props;
  const { formatText } = useLocalization();
  const optionProps = {
    disabled: true,
    hidden: true,
    selected: true,
  };

  return (
    <UitkSelect
      id="cruise-destination"
      name={name}
      label={formatText(locKeys.destinationLabel)}
      aria-label={formatText(locKeys.destinationLabel)}
      onChange={onChange}
      value={value}
      data-testid="cruise-destination"
      invalid={destinationInvalidKey ? formatText(destinationInvalidKey) : ""}
      domRef={inputRef}
    >
      <option data-testid="destination-placeholder-option" value="" {...optionProps}>
        {formatText(locKeys.destinationPlaceholder)}
      </option>

      {Object.keys(DESTINATION_GROUPS).map((groupKey) => {
        const group: IDestinationGroup = DESTINATION_GROUPS[groupKey as keyof IDestinationGroups];

        return (
          <optgroup key={groupKey} label={formatText(group.locKey)}>
            {group.items.map(({ value, locKey }) => (
              <option key={value} value={value}>
                {formatText(locKey)}
              </option>
            ))}
          </optgroup>
        );
      })}
    </UitkSelect>
  );
};
