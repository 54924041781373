export const extraInputsAu = [
  {
    name: "cobrand",
    value: "36087",
  },
  {
    name: "webpagerecno",
    value: "5412",
  },
  {
    name: "sort",
    value: "4",
  },
  {
    name: "bs",
    value: "1",
  },
];
