import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { locKeys } from "components/flexComponents/WizardCruisePWA/l10n";
import { UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { DESTINATION_GROUPS } from "./destinations";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { UitkFakeInput } from "@egds/react-core/input";
import { CruiseWizardState } from "stores/wizard/state";
import { CruiseDestinationDialog } from "./CruiseDestinationDialog";
import { CruiseDestinationSelect } from "./CruiseDestinationSelect";
import { UitkSheetTransition } from "@egds/react-core/sheet";

export interface CruiseDestinationProps {
  lobState: CruiseWizardState;
  colSpan: UitkLayoutConditionalGridSpan;
}

export const CruiseDestination = observer(({ lobState, colSpan }: CruiseDestinationProps) => {
  const inputRef = React.useRef<any | null>(null);
  if (!lobState.wizardInputsArray.includes(inputRef)) {
    lobState.wizardInputsArray.push(inputRef);
  }

  const destinationForDisplayKey = (selectedDestination?: string) => {
    return selectedDestination
      ? [...DESTINATION_GROUPS.mostPopular.items, ...DESTINATION_GROUPS.other.items].find(
          (key) => key.value === selectedDestination
        )?.locKey
      : "";
  };

  const { formatText } = useLocalization();
  const [selectName, setSelectName] = React.useState("destination");
  const destinationForDisplayLoc = destinationForDisplayKey(lobState.cruiseDestination);
  const [destinationForDisplay, setDestinationForDisplay] = React.useState(
    destinationForDisplayLoc ? formatText(destinationForDisplayLoc) : ""
  );
  const [useHiddenInputs, setUseHiddenInputs] = React.useState(false);
  const [isDialogOpen, dialogActions, CruiseDialogComponent] = useDialog("cruise-destination");

  const onDestinationSelect = (destinationValue: string, newDestinationForDisplay: string) => {
    lobState.setCruiseDestination(destinationValue);
    setDestinationForDisplay(newDestinationForDisplay);
    dialogActions.closeDialog();
  };

  /* istanbul ignore next */
  const onDestinationSelectChange = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    lobState.setCruiseDestination(selectEvent.currentTarget.value);
  };

  /**
   * This hook is reached for rehydratation, so if JS is enabled there is no need of keep setting a name to UitkSelect component,
   * also we need to render the hidden input since it manages the select value, if JS is disabled then this hook is never executed
   */
  React.useEffect(() => {
    setSelectName("");
    setUseHiddenInputs(true);
  }, []);

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        <Viewport>
          <ViewSmall>
            <>
              <UitkFakeInput
                id="cruise-destination-trigger"
                className="cruise-destination-dialog-trigger"
                data-testid="cruise-destination-dialog-trigger"
                name={selectName}
                label={formatText(locKeys.destinationLabel)}
                placeholder={formatText(locKeys.destinationPlaceholder)}
                value={destinationForDisplay}
                onClick={dialogActions.openDialog as any}
                icon="place"
                invalid={lobState.destinationInvalidKey ? formatText(lobState.destinationInvalidKey) : ""}
                domRef={inputRef}
              />
              <UitkSheetTransition isVisible={isDialogOpen}>
                <CruiseDialogComponent>
                  <CruiseDestinationDialog
                    selectedDestination={lobState.cruiseDestination}
                    onSave={onDestinationSelect}
                    destinationGroups={DESTINATION_GROUPS}
                  />
                </CruiseDialogComponent>
              </UitkSheetTransition>
            </>
          </ViewSmall>
          <ViewMedium>
            <CruiseDestinationSelect
              onChange={onDestinationSelectChange}
              name={selectName}
              value={lobState.cruiseDestination}
              destinationInvalidKey={lobState.destinationInvalidKey}
              inputRef={inputRef}
            />
          </ViewMedium>
        </Viewport>
        {useHiddenInputs && (
          <input
            type="hidden"
            data-testid="wizard-cruise-destination"
            name="destination"
            value={lobState.cruiseDestination}
          />
        )}
      </div>
    </UitkLayoutGridItem>
  );
});

export default CruiseDestination;
