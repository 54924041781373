import { CruiseWizardState } from "src/stores/wizard/state";

/* istanbul ignore next */
export const getLocError = (isLink: boolean, cruiseWizardState: CruiseWizardState) => {
  const { config } = cruiseWizardState;

  if (!isLink) {
    return cruiseWizardState.numberOfErrors
      ? config.form.submitButton.locWarningsWithNumberToken
      : config.form.submitButton.locWarningsToken;
  }

  return cruiseWizardState.moreThanOneError()
    ? config.form.submitButton.locFirstWarningLinkToken
    : config.form.submitButton.locWarningLinkToken;
};
