export const extraInputsDe = [
  {
    name: "fuseaction",
    value: "search.index",
  },
  {
    name: "partnerID",
    value: "112186000000",
  },
  {
    name: "usersearch",
    value: "1",
  },
];
