import * as React from "react";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { WizardCruisePWAProps } from "./typings";
import { CruiseDestination } from "components/flexComponents/WizardCruisePWA/components/CruiseDestination";
import { observer } from "mobx-react";
import { LobTitle } from "src/components/shared/LobTitle/LobTitle";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { Dates } from "src/components/shared/Dates/Dates";
import { Travelers } from "src/components/shared/Travelers/Travelers";
import { LobSubtitle } from "src/components/shared/LobSubtitle/LobSubtitle";
import { NoRehydratationValues, TravelersTriggerTypes } from "src/components/shared/TravelersField/typings";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { Location } from "src/components/shared/Location/Location";
import { CruiseHiddenSearchData } from "./components/CruiseHiddenSearchData";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { getLocError } from "./utils/WizardCruisePWAUtils";

export const WizardCruisePWAView: React.FunctionComponent<WizardCruisePWAProps> = observer(
  ({ templateComponent, wizardState, context, page }) => {
    const [startId, setStartId] = React.useState<string | undefined>("earliest-departure-date");
    const [endId, setEndId] = React.useState<string | undefined>("latest-departure-date");

    /**
     * This hook is reached for rehydratation, so if JS is enabled there is no need of keep setting IDs to elements
     * so value is changed to 'undefined' and so it is avoided, if JS is disabled then this hook is never executed
     */
    React.useEffect(() => {
      setStartId(undefined);
      setEndId(undefined);
    }, []);

    const {
      globalWizardState: { config: globalConfig },
      cruiseWizardState,
      cruiseWizardState: { config: cruiseConfig },
    } = wizardState;
    const { visibleLobSubtitle } = cruiseWizardState.config.heading;
    const { defaultDestination, defaultMultipleDestination, halfWizard } = globalConfig || {};

    const { numberOfErrors, errorInputRef } = cruiseWizardState;

    const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 1, medium: 6 };
    const destinationColSpan: UitkLayoutConditionalGridSpan = {
      small: 1,
      medium: 2,
    };
    const travelersColSpan: UitkLayoutConditionalGridSpan = { small: 1, medium: 3 };
    const datesColSpan: UitkLayoutConditionalGridSpan = {
      small: 1,
      medium: halfWizard ? 3 : 4,
    };

    /**
     * Values used if no rehydratation(JS disabled)
     */
    const noRehydratationValues: NoRehydratationValues = {
      numOfTravelers: 2,
      name: "adultCount",
    };

    const setInputsRefs = (ref: React.RefObject<HTMLInputElement>) => {
      cruiseWizardState.wizardInputsArray.push(ref);
    };

    const subtitleTravelersInlinePadding: UitkSpacingProps["padding"] = { block: "two" };
    const subtitleTravelersInlineMargin: UitkSpacingProps["margin"] = { block: "half" };

    const pageName = page ? page.pageName : "unknown";
    const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
    const linkName = "CruiseSearch";
    const sectionName = "SQM";
    const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;

    const extraTracking: any = {
      schemaName: "referrer",
      messageContent: {
        referrerId: rfrrid,
        eventType: Action.CLICK,
      },
    };

    const fmTitleId = templateComponent.config.fmTitleId;
    const fmId = getFmId(templateComponent);

    return (
      <form
        noValidate
        action={cruiseConfig.form.action}
        autoComplete="off"
        className="WizardCruisePWA"
        id={cruiseConfig.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
        data-testid="wizard"
        onSubmit={cruiseWizardState.submit}
      >
        <LobTitle lobState={cruiseWizardState} />

        <Viewport>
          <ViewSmall>
            <>
              {visibleLobSubtitle && (
                <UitkSpacing padding={subtitleTravelersInlinePadding}>
                  <LobSubtitle lobState={cruiseWizardState} />
                </UitkSpacing>
              )}
              <UitkSpacing padding={subtitleTravelersInlinePadding}>
                <Travelers
                  lobState={cruiseWizardState}
                  colSpan={travelersColSpan}
                  noRehydratationValues={noRehydratationValues}
                  triggerType={TravelersTriggerTypes.LINK_BUTTON}
                />
              </UitkSpacing>
            </>
          </ViewSmall>
          <ViewMedium>
            <UitkSpacing padding={subtitleTravelersInlinePadding} margin={subtitleTravelersInlineMargin}>
              <UitkLayoutFlex data-testid="travelers-inline-link" alignItems="center" justifyContent="space-between">
                {visibleLobSubtitle && (
                  <UitkLayoutFlexItem>
                    <LobSubtitle lobState={cruiseWizardState} />
                  </UitkLayoutFlexItem>
                )}
                <UitkLayoutFlexItem>
                  <div>
                    <Travelers
                      lobState={cruiseWizardState}
                      colSpan={travelersColSpan}
                      noRehydratationValues={noRehydratationValues}
                      triggerType={TravelersTriggerTypes.LINK_BUTTON}
                    />
                  </div>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkSpacing>
          </ViewMedium>
        </Viewport>

        <UitkSpacing padding={{ block: "three" }}>
          <UitkLayoutGrid columns={mainGridColumns} space="three">
            <>
              {numberOfErrors > 0 && (
                <LobErrorSummary
                  locHeadingToken={getLocError(false, cruiseWizardState) ?? ""}
                  locHeadingArgs={cruiseWizardState.moreThanOneError() && [numberOfErrors]}
                  locLinkToken={getLocError(true, cruiseWizardState)}
                  inputErrorRef={errorInputRef}
                  colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
                  setInputsRefs={setInputsRefs}
                />
              )}
              {!defaultDestination && !defaultMultipleDestination && (
                <CruiseDestination lobState={cruiseWizardState} colSpan={destinationColSpan} />
              )}

              {(defaultDestination || defaultMultipleDestination) && (
                <Location
                  originField={false}
                  destinationField
                  lobState={cruiseWizardState}
                  colSpan={travelersColSpan}
                  globalConfig={globalConfig}
                />
              )}
              <Viewport>
                <ViewSmall>
                  <Dates
                    startId={startId}
                    endId={endId}
                    lobState={cruiseWizardState}
                    colSpan={datesColSpan}
                    stackedDates
                  />
                </ViewSmall>
                <ViewMedium>
                  <Dates startId={startId} endId={endId} lobState={cruiseWizardState} colSpan={datesColSpan} />
                </ViewMedium>
              </Viewport>
            </>
          </UitkLayoutGrid>
        </UitkSpacing>
        <CruiseHiddenSearchData cruiseWizardState={cruiseWizardState} />

        <SubmitRow
          lobState={cruiseWizardState}
          rfrr="SearchClick_Cruises"
          extraUISTracking={extraTracking}
          globalConfig={globalConfig}
        />
      </form>
    );
  }
);

export default WizardCruisePWAView;
