import * as React from "react";
import { WizardCruisePWAProps } from "./typings";
import { observer } from "mobx-react";
import { LobTitle } from "src/components/shared/LobTitle/LobTitle";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { LobSubtitle } from "src/components/shared/LobSubtitle/LobSubtitle";
import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { CruiseCommon3PPSelect } from "./components/CruiseCommon3PPSelect";
import { useLocalization } from "@shared-ui/localization-context";
import { Cruise3PPDates } from "./components/Cruise3PPDates";
import { Dates } from "src/components/shared/Dates/Dates";
import { extraInputsAu } from "./components/customData/extraInputsAu";
import { extraInputsDe } from "./components/customData/extraInputsDe";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { UitkSpacing } from "@egds/react-core/spacing";

export const WizardCruisePWA3PPView: React.FunctionComponent<WizardCruisePWAProps> = observer(
  ({ templateComponent, wizardState, context }) => {
    const { formatText } = useLocalization();
    const {
      cruiseWizardState,
      cruiseWizardState: { config: cruiseConfig },
    } = wizardState;

    const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 1, medium: 6 };

    const destinationDatesColSpan: UitkLayoutConditionalGridSpan = {
      small: 1,
      medium: 3,
    };

    const portDurLineColSpan: UitkLayoutConditionalGridSpan = {
      small: 1,
      medium: 2,
    };

    const { wizard3pp } = cruiseConfig;
    const {
      labelLocKeys,
      queryParam,
      destinations,
      departurePorts,
      cruiseLines,
      durations,
      actionUrl,
      dateRanges,
    } = wizard3pp!;

    const changeDestination = (destination: string) => {
      cruiseWizardState.updateDestination3pp(destination);
    };

    const changeDeparture = (departure: string) => {
      cruiseWizardState.updateDeparturePort3pp(departure);
    };

    const changeDuration = (duration: string) => {
      cruiseWizardState.updateDuration3pp(duration);
    };

    const changeCruiseLine = (cruiseLine: string) => {
      cruiseWizardState.updateCruiseLine3pp(cruiseLine);
    };

    const changeDateRange = (dateRange: string) => {
      cruiseWizardState.updateDateRange3pp(dateRange);
    };

    const siteId = context.site.id;
    const siteIdsInputs: any = {
      6: extraInputsDe,
      25: extraInputsAu,
      70125: extraInputsAu,
    };
    const customDateSiteIds = [25, 70125];

    cruiseWizardState.siteId = siteId;

    const datesView = customDateSiteIds.includes(siteId) ? (
      <Cruise3PPDates
        colSpan={destinationDatesColSpan}
        icon="today"
        placeholder={formatText(labelLocKeys.dateRange)}
        id="cruise-3pp-dateRange"
        items={dateRanges}
        param={queryParam.dateRange}
        currentValue={cruiseWizardState.dateRange3pp}
        onChange={changeDateRange}
      />
    ) : (
      <Dates lobState={cruiseWizardState} colSpan={destinationDatesColSpan} singleDate={false} />
    );

    const globalConfig = wizardState.globalWizardState.config;

    if (!templateComponent) {
      return null;
    }
    const fmTitleId = templateComponent.config.fmTitleId;
    const fmId = getFmId(templateComponent);

    return (
      <form
        noValidate
        action={actionUrl}
        autoComplete="off"
        className="WizardCruise3ppPWA"
        id={cruiseConfig.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
        data-testid="wizard-cruise-3pp"
        onSubmit={cruiseWizardState.submit3pp}
      >
        <LobTitle lobState={cruiseWizardState} />
        {cruiseWizardState.config.heading.visibleLobSubtitle && (
          <UitkSpacing padding={{ blockend: "four" }} margin={{ blockstart: "one" }}>
            <div>
              <LobSubtitle lobState={cruiseWizardState} />
            </div>
          </UitkSpacing>
        )}

        {
          // Fix values in AU to make the search
          siteIdsInputs.hasOwnProperty(siteId) && (
            <>
              {siteIdsInputs[siteId].map((item: { name: string; value: string }) => (
                <input key={item.name} type="hidden" name={item.name} value={item.value} />
              ))}
            </>
          )
        }
        <UitkSpacing margin={{ blockstart: "three" }}>
          <UitkLayoutGrid columns={mainGridColumns} space="three">
            <>
              <CruiseCommon3PPSelect
                colSpan={destinationDatesColSpan}
                icon="place"
                placeholder={formatText(labelLocKeys.destination)}
                id="cruise-3pp-destination"
                items={destinations}
                param={queryParam.destination}
                currentValue={cruiseWizardState.destination3pp}
                onChange={changeDestination}
              />

              {datesView}

              <CruiseCommon3PPSelect
                colSpan={portDurLineColSpan}
                icon="place"
                placeholder={formatText(labelLocKeys.departure)}
                id="cruise-3pp-departure"
                items={departurePorts}
                param={queryParam.departure}
                currentValue={cruiseWizardState.departurePort3pp}
                onChange={changeDeparture}
              />

              <CruiseCommon3PPSelect
                colSpan={portDurLineColSpan}
                icon="access_time"
                placeholder={formatText(labelLocKeys.duration)}
                id="cruise-3pp-duration"
                items={durations}
                param={queryParam.duration}
                currentValue={cruiseWizardState.duration3pp}
                onChange={changeDuration}
              />

              <CruiseCommon3PPSelect
                colSpan={portDurLineColSpan}
                icon="lob_cruise"
                placeholder={formatText(labelLocKeys.cruiseLine)}
                id="cruise-3pp-cruiseline"
                items={cruiseLines}
                param={queryParam.cruiseLine}
                currentValue={cruiseWizardState.cruiseLine3pp}
                onChange={changeCruiseLine}
              />
            </>
          </UitkLayoutGrid>
        </UitkSpacing>
        <SubmitRow lobState={cruiseWizardState} rfrr="SearchClick_Cruises" globalConfig={globalConfig} />
      </form>
    );
  }
);

export default WizardCruisePWA3PPView;
