import * as React from "react";
import { UitkSelect } from "@egds/react-core/input";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { CruiseCommon3PPSelectProps } from "../typings";

export const CruiseCommon3PPSelect = observer((props: CruiseCommon3PPSelectProps) => {
  const { colSpan, icon, placeholder, id, currentValue, items, param, onChange } = props;

  const commonKeyHelper = new ItemKeyHelper(`common-3pp-select-${id}`);

  const changeValue = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(selectEvent.target.value);
  };

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        {param && <input type="hidden" name={param} value={currentValue} />}
        <UitkSelect
          icon={icon}
          id={id}
          label={placeholder}
          value={currentValue || undefined}
          onChange={changeValue}
          emptyState
        >
          {items.map(({ value, text }) => (
            <option value={value} key={commonKeyHelper.next()}>
              {text}
            </option>
          ))}
        </UitkSelect>
      </div>
    </UitkLayoutGridItem>
  );
});
