import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkFloatingFullWidthButton, UitkButtonSize } from "@egds/react-core/button";
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkRadioButton, UitkRadioButtonGroup } from "@egds/react-core/radio-button";
import { UitkText } from "@egds/react-core/text";
import { UitkToolbar } from "@egds/react-core/toolbar";

import { useLocalization } from "@shared-ui/localization-context";

import { locKeys } from "components/flexComponents/WizardCruisePWA/l10n";
import { IDestinationGroups, IDestinationGroup } from "./destinations";

export interface CruiseDestinationDialogProps {
  selectedDestination?: string;
  destinationGroups: IDestinationGroups;
  onSave: (destinationValue: string, destinationForDisplay: string) => void;
}

export const CruiseDestinationDialog: React.FC<CruiseDestinationDialogProps> = (props) => {
  const { selectedDestination, destinationGroups, onSave } = props;
  const { formatText } = useLocalization();
  const [isOpen, dialogActions] = useDialog("cruise-destination");
  const ref = React.createRef<HTMLElement>();

  /* istanbul ignore next */
  const [destinationValue, setDestinationValue] = React.useState(selectedDestination ?? "");
  const [destinationForDisplay, setDestinationForDisplay] = React.useState("");

  const onDoneButtonClick = () => {
    onSave(destinationValue, destinationForDisplay);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, labels } = event.currentTarget;
    // @ts-ignore
    const [label] = labels;

    setDestinationValue(value);
    setDestinationForDisplay(label.textContent as string);
  };

  return (
    <UitkSheetTransition isVisible={isOpen}>
      <UitkSheet isVisible={isOpen} type="full" triggerRef={ref}>
        <UitkToolbar
          toolbarTitle={formatText(locKeys.destinationLabel)}
          navigationContent={{
            navIconLabel: formatText(locKeys.dismissDialog),
            onClick: dialogActions.closeDialog as any,
          }}
        />
        <UitkSpacing margin={{ inlinestart: "two" }}>
          <UitkSheetContent>
            {Object.keys(destinationGroups).map((groupKey) => {
              const group: IDestinationGroup = destinationGroups[groupKey as keyof IDestinationGroups];

              return (
                <UitkSpacing padding={{ block: "three" }} key={groupKey}>
                  <section>
                    <UitkSpacing padding={{ block: "three" }}>
                      <UitkText size={400} weight="bold">
                        {formatText(group.locKey)}
                      </UitkText>
                    </UitkSpacing>

                    <UitkRadioButtonGroup
                      group="cruise-destination-group"
                      onChange={handleRadioChange}
                      selected={destinationValue}
                    >
                      {group.items.map(({ value, locKey }) => (
                        <UitkRadioButton
                          key={`cruise-destination-radio-${value}`}
                          id={`cruise-destination-radio-${value}`}
                          value={value}
                        >
                          {formatText(locKey)}
                        </UitkRadioButton>
                      ))}
                    </UitkRadioButtonGroup>
                  </section>
                </UitkSpacing>
              );
            })}
          </UitkSheetContent>
        </UitkSpacing>
        <UitkSpacing padding="three">
          <footer>
            <UitkFloatingFullWidthButton
              onClick={onDoneButtonClick}
              className="cruise-destination-dialog-confirm"
              size={UitkButtonSize.LARGE}
              data-testid="cruise-destination-dialog-confirm"
            >
              {formatText(locKeys.dialogDoneButton)}
            </UitkFloatingFullWidthButton>
          </footer>
        </UitkSpacing>
      </UitkSheet>
    </UitkSheetTransition>
  );
};
